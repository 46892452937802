import(/* webpackMode: "eager", webpackExports: ["BasePageWrapper"] */ "/builds/cxnpl/webapp/apps/next/app/_components/BasePageWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/components/FullScreenError/FullScreenError.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/ForbiddenError.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/InvalidSessionError.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/PageNotFoundError.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/PageNotFoundErrorSecure.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/UnavailableError.tsx");
;
import(/* webpackMode: "eager" */ "/builds/cxnpl/webapp/packages/app/features/errors/UnexpectedError.tsx");
